<template>
  <button class="close-button">&#x2715;</button>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.close-button {
  background: none;
  border: none;
  cursor: pointer;

  font-size: 2rem;
  font-weight: 800;
  height: 60px;
  user-select: none;
  width: 60px;

  @include queries.for-size(tablet-landscape-down) {
    height: 40px;
    width: 40px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
