<template>
  <div class="feature ever-feature reverse">
    <HomeFeatureContentContainer>
      <img :src="`${useCdnUrl()}/assets/ever/ever-logo.svg`" alt="Ever" class="section-logo" />
      <h2>Intimate Vows,<br />Iconic Views</h2>
      <p>
        Welcome to EVER, where the magic of New York City becomes a main character in your love
        story. Wedding ceremonies, vow renewals, proposals, and promise pledges at EVER offer an
        unparalleled experience as you declare your love in one of the world's most iconic settings.
      </p>

      <p class="coming-soon">Be the first to celebrate your love at the iconic One Times Square</p>

      <TheButton
        to="/ever"
        tag="NuxtLink"
        class="ever-callout"
        aria-label="Reserve your wedding date here"
        >Reserve your date</TheButton
      >
      <p class="note">
        Have questions about how to celebrate with us? Connect with our concierge at
        <TheNuxtLink
          to="mailto:Ever@OneTimesSquare.com"
          aria-label="Email us at Ever@OneTimesSquare.com"
          analytics-target="email-ever"
          >Ever@OneTimesSquare.com</TheNuxtLink
        >
      </p>
    </HomeFeatureContentContainer>

    <div class="feature-image-effect">
      <SplitImage
        :srcs="[
          `${useCdnUrl()}/assets/ever/ever-poster-alt.webp`,
          `${useCdnUrl()}/assets/ever/ever-poster.webp`,
        ]"
        :class="'align-image-right'"
        :alt="['Ever feature image']"
      />
    </div>

    <HomeFeatureMobileImage
      :srcs="[
        `${useCdnUrl()}/assets/ever/ever-poster-alt.webp`,
        `${useCdnUrl()}/assets/ever/ever-poster.webp`,
      ]"
      ><img :src="`${useCdnUrl()}/assets/ever/ever-logo.svg`" alt="Ever"
    /></HomeFeatureMobileImage>
  </div>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
.ever-feature {
  @include queries.for-size(phone-only) {
    height: auto !important;
    min-height: 100vh !important;
  }
  .feature-content {
    background-color: var(--ever-primary-color);

    .content-container {
      @include queries.for-size(tablet-landscape-up) {
        gap: 3.5rem !important;
        padding: 2rem 8rem 0 8rem;
      }
    }

    .ever-callout {
      background-color: var(--ever-complimentary-color);
      border-width: 0;

      &:hover {
        color: black;
      }
    }
  }

  .feature-image-effect {
    .split-image {
      .split-slider {
        background-color: var(--ever-primary-color) !important;
      }
    }
  }
}
</style>
