<script setup lang="ts">
import type { ActionTypes } from "@/types/";
const { sendScreenView } = useAnalytics();

const props = defineProps<{
  analyticsAction?: ActionTypes;
  analyticsTarget?: string | undefined;
}>();

function trackView(target: string | undefined): void {
  if (target) {
    sendScreenView(
      {
        type: props.analyticsAction || "click",
        target: target,
      },
      true
    );
  }
}
</script>

<template>
  <NuxtLink v-bind="$attrs" @click="trackView(props.analyticsTarget)">
    <slot />
  </NuxtLink>
</template>
