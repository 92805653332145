<script setup lang="ts">
import type { PostData } from "@/types";
const props = defineProps<{
  url: string;
}>();

const { data } = await useFetch<PostData>(`${useCdnUrl()}/json/features/${props.url}`, {
  server: true,
});
</script>

<template>
  <li v-if="data">
    <TheNuxtLink
      :to="data.url && data.newWindow ? data.url : data.url"
      :target="data.url && data.newWindow ? '_blank' : '_self'"
      :aria-label="`Read more - ${data.title}`"
      :analytics-target="data.newWindow ? data.url : undefined"
    >
      <NuxtImg
        v-if="data.properties.background"
        :src="`${useCdnUrl()}${data.properties.background}.webp`"
        :alt="`${data.title} Image`"
      />
      <div class="title">
        <NuxtImg v-if="data.titleImage" :src="`${useCdnUrl()}${data.titleImage}.svg`" />
        <h2 v-else>{{ data.title }}</h2>
      </div>
      <p>{{ data.content }}</p>
      <div class="more-icon">+</div>
    </TheNuxtLink>
  </li>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
li {
  background-color: black;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  a {
    display: block;
    .more-icon {
      font-size: clamp(5rem, 2.5vw, 6rem);
      font-weight: 800;
      line-height: 1;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
    img {
      height: 100%;
      object-fit: cover;
      opacity: 1;
      position: absolute;
      width: 100%;
    }

    .title {
      padding: 2rem;
      position: absolute;
      text-align: center;

      top: calc(100% - 20px);
      transform: translateY(-100%);
      width: 100%;
      z-index: 1;

      img {
        margin: auto;

        min-height: 100px;
        position: relative;
        width: 100%;

        @include queries.for-size(phone-only) {
          min-height: auto;
        }
      }

      h2 {
        color: #fff;

        font-weight: 800;
        left: 20px;
        line-height: 100.016%;
        margin: 0;
        text-align: left;

        text-transform: uppercase;
        @include queries.for-size(desktop-up) {
          font-size: clamp(3rem, 2.5vw, 4rem);
        }

        @media (max-width: 1420px) {
          font-size: clamp(2.25rem, 2.5vw, 3rem);
        }

        @include queries.for-size(phone-only) {
          font-size: 2.5rem;
          padding: 0 2rem 0 0;
          text-shadow: 0 0 7px #000;
        }
      }
    }

    p {
      bottom: 10px;
      color: #fff;
      font-size: clamp(1.85rem, 1.75vw, 2.5rem);
      font-style: normal;
      font-weight: 500;
      left: 20px;
      line-height: 1.1;
      opacity: 0;
      padding: 2rem 2rem 0 2rem;
      position: absolute;

      @media (max-width: 600px) {
        padding-left: 0;
      }
    }

    .more-icon,
    img,
    div,
    p {
      transition: all 0.5s ease-out;
    }

    &:hover {
      .more-icon {
        opacity: 0;
      }

      .title {
        top: 5%;
        transform: translateY(0);
      }

      p {
        opacity: 1;
      }

      img {
        opacity: 0.2;
      }
    }
  }
}
</style>
