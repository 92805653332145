<script setup lang="ts">
defineProps<{
  collapsed?: boolean;
}>();

const isMobile = ref(false);
const toggleState = ref<"open" | "closed" | undefined>();

const { isMobileDevice } = useUtils();
const isMounted = ref(false);
const route = useRoute();
const router = useRouter();

onMounted(() => {
  isMounted.value = true;
  isMobile.value = isMobileDevice();
});

function gotoTarget(hash: string) {
  if (route.name === "index") {
    const element = document.getElementById(hash);
    element!.scrollIntoView({ block: "start", behavior: "smooth" });
  } else {
    router.push(`/#${hash}`);
  }

  if (isMobile.value) {
    toggleState.value = "closed";
  }
}
</script>

<template>
  <div class="main-nav" :class="{ mobile: isMobile }">
    <div v-if="isMounted" class="nav-bar desktop-nav-bar">
      <nav>
        <ul>
          <li>
            <TheNuxtLink
              to="/#timesTravelSection"
              aria-label="Learn more about Times Travel"
              @click.prevent="gotoTarget('timesTravelSection')"
              >Viewing Deck</TheNuxtLink
            >
          </li>
          <li>
            <TheNuxtLink
              to="/#everSection"
              aria-label="Learn more about Ever"
              @click.prevent="gotoTarget('everSection')"
              >Ever</TheNuxtLink
            >
          </li>
          <li>
            <TheNuxtLink
              to="/#postsSection"
              aria-label="Read our latest news and posts"
              @click.prevent="gotoTarget('postsSection')"
              >News</TheNuxtLink
            >
          </li>
        </ul>
      </nav>
    </div>

    <div v-if="toggleState !== 'open'" class="toggle-menu">
      <UiHamburgerButton aria-label="Open menu" @click="toggleState = 'open'" />
    </div>

    <div
      class="nav-bar mobile-nav-bar"
      :class="{ 'menu-open': toggleState === 'open', 'menu-closed': toggleState === 'closed' }"
    >
      <UiCloseButton color="white" aria-label="Close menu" @click="toggleState = 'closed'" />
      <nav>
        <ul>
          <li>
            <TheNuxtLink
              to="/#timesTravelSection"
              aria-label="Learn more about Times Travel"
              @click.prevent="gotoTarget('timesTravelSection')"
              >Viewing Deck</TheNuxtLink
            >
          </li>
          <li>
            <TheNuxtLink
              to="/#everSection"
              aria-label="Learn more about Ever"
              @click.prevent="gotoTarget('everSection')"
              >Ever</TheNuxtLink
            >
          </li>
          <li>
            <TheNuxtLink
              to="/#postsSection"
              aria-label="Read our latest news and posts"
              @click.prevent="gotoTarget('postsSection')"
              >News</TheNuxtLink
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
@use "@/assets/scss/transitions";

.main-nav {
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;

  .nav-bar {
    display: flex;
    justify-content: flex-end;
    padding: 3rem 4rem 3rem 4rem;
  }

  .desktop-nav-bar {
    display: none;
    @media (min-width: 1025px) {
      display: block;
    }
    nav {
      width: 100%;
      ul {
        display: flex;
        flex-direction: row;
        gap: 5rem;
        list-style-type: none;
        padding: 0;
        width: 100%;

        li {
          text-align: right;
          a {
            color: #fff;
            cursor: pointer;
            font-size: clamp(1.5rem, 2.5vw, 2.25rem);
            font-style: normal;
            font-weight: 900;
            line-height: 1;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .toggle-menu {
    display: none;
    padding: 2rem 3rem;
    position: absolute;
    right: 0;
    text-align: right;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .mobile-nav-bar {
    background-color: black;
    border-bottom-left-radius: 3rem;

    flex-direction: column;
    gap: 4rem;
    min-width: 30vw;
    position: absolute;
    right: 0;
    top: 0;

    transform: translateY(-100%);

    @include queries.for-size(desktop-down) {
      min-width: 50vw;
    }

    @include queries.for-size(phone-only) {
      min-width: 60vw;
    }

    &.menu-closed {
      animation: menu-closed 0.5s forwards;
    }

    &.menu-open {
      animation: menu-open 0.5s forwards;
    }

    @keyframes menu-open {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes menu-closed {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-100%);
      }
    }

    button {
      color: white;
      font-size: 5rem;
      margin-left: auto;
      padding: 0;
    }

    nav {
      width: 100%;
      ul {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        justify-content: flex-end;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
          text-align: right;
          a {
            color: #fff;
            cursor: pointer;
            font-size: 3.2rem;
            font-style: normal;
            font-weight: 900;
            line-height: 1;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@include transitions.fade;
</style>
