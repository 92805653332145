<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  repeat: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();

const inView = ref(false);
const splitImage = ref<HTMLElement>();
let itemObserver: IntersectionObserver | null = null;

const sliderLeftTop = ref(1500);
const sliderRightTop = ref(-1500);

let hasTransitioned = false;

onMounted(() => {
  sliderLeftTop.value = window.innerHeight;
  sliderRightTop.value = window.innerHeight * -1;

  itemObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          inView.value = true;
          onWatchScroll();
        } else {
          inView.value = false;
        }
      });
    },
    {
      threshold: 0.3,
    }
  );

  itemObserver.observe(splitImage.value!);
});

function onWatchScroll() {
  if (inView.value && !hasTransitioned) {
    const windowOffsetHeight = window.innerHeight - 90;
    const percentTop = (splitImage.value!.getBoundingClientRect().top - 90) / windowOffsetHeight;

    if (percentTop < 0.2) {
      sliderLeftTop.value = 0;
      sliderRightTop.value = 0;

      if (!props.repeat) {
        hasTransitioned = true;
        itemObserver!.unobserve(splitImage.value!);
      }
    } else {
      if (percentTop >= 0.2 && percentTop <= 0.8) {
        sliderLeftTop.value = percentTop * windowOffsetHeight;
        sliderRightTop.value = splitImage.value!.getBoundingClientRect().bottom * percentTop * -1;
      } else if (percentTop < 0.7) {
        sliderLeftTop.value = splitImage.value!.offsetHeight * percentTop;
        sliderRightTop.value = sliderLeftTop.value * -2;
      }
    }

    requestAnimationFrame(onWatchScroll);
  }
}
</script>

<template>
  <div ref="splitImage" class="split-image">
    <div class="wrapper-div">
      <div class="split-slider" :class="attrs.class">
        <div class="slider-left" :style="{ transform: `translateY(${sliderLeftTop}px)` }">
          <div :class="{ fadein: attrs.srcs.length > 1, hasTransitioned }">
            <NuxtImg
              v-for="(img, index) in attrs.srcs"
              :key="img"
              :src="img"
              :alt="attrs.alt.length > 1 ? attrs.alt[index] : attrs.alt[0]"
            />
          </div>
        </div>

        <div class="slider-right" :style="{ transform: `translateY(${sliderRightTop}px)` }">
          <div :class="{ fadein: attrs.srcs.length > 1, hasTransitioned }">
            <NuxtImg
              v-for="(img, index) in attrs.srcs"
              :key="img"
              :src="img"
              :alt="attrs.alt.length > 1 ? attrs.alt[index] : attrs.alt[0]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.split-image {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .wrapper-div {
    height: 100%;
    position: relative;
  }

  .split-slider {
    height: 100%;
    position: absolute;
    width: 100%;

    > div {
      display: flex;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: all 0.75s ease-out;

      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
      }

      &.slider-left {
        background-color: black;
        mask-image: linear-gradient(90deg, #000 50%, rgba(0, 0, 0, 0) 50%);
      }

      &.slider-right {
        mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, black 50%);
      }

      .fadein {
        img {
          position: absolute;
          top: 0;
        }

        &.hasTransitioned img:last-of-type {
          animation-duration: 18s;
          animation-iteration-count: infinite;
          animation-name: fade;
          animation-timing-function: linear;
        }
      }
    }

    &.align-image-right > div img {
      object-position: right center;
    }

    &.align-image-left > div img {
      object-position: left center;
    }
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
