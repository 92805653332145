<template>
  <div class="social">
    <h3>Follow Us</h3>

    <ul>
      <li>
        <TheNuxtLink
          to="https://www.facebook.com/onetimessquarenyc/"
          aria-label="Follow us on Facebook"
          target="_blank"
          analytics-target="facebook"
        >
          <nuxt-img
            :src="`${useCdnUrl()}/assets/logos/logo-fb-simple.svg`"
            width="50"
            height="50"
            alt="Follow us on Facebook"
          />
        </TheNuxtLink>
      </li>
      <li>
        <TheNuxtLink
          to="https://www.instagram.com/onetimessquarenyc"
          aria-label="Follow us on Instagram"
          target="_blank"
          analytics-target="instagram"
        >
          <nuxt-img
            :src="`${useCdnUrl()}/assets/logos/logo-instagram.svg`"
            width="50"
            height="50"
            alt="Follow us on Instagram"
          />
        </TheNuxtLink>
      </li>
      <li>
        <TheNuxtLink
          to="https://www.tiktok.com/@onetimessquarenyc"
          aria-label="Follow us on TikTok"
          target="_blank"
          analytics-target="tiktok"
        >
          <nuxt-img
            :src="`${useCdnUrl()}/assets/logos/logo-tiktok.svg`"
            width="50"
            height="50"
            alt="Follow us on TikTok"
          />
        </TheNuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.social {
  text-align: center;

  ul {
    align-items: center;

    display: flex;
    gap: 5rem;
    justify-content: center;
    list-style-type: none;
    margin: 2rem 0;
    padding: 0;

    img {
      height: 50px;
    }
  }
}
</style>
