<script setup lang="ts">
import { useAppVersion } from "./composables/app-version";

const { appName } = useEnvPaths();

const { isConsent, sendScreenView } = useAnalytics();
const consent = useCookie(`${appName()}_cookieConsent`);

// Logs app version to console
const appVersion = await useAppVersion();

// Needed for page analytics calls. This should be made better in future
const appMounted = useState("appMounted", () => false);

onMounted(() => {
  window.scrollTo(0, 1);

  const { checkGdprLocale } = useGdprStore();
  checkGdprLocale();

  const { getAnalyticsUrl } = useEnvPaths();
  const { getSessionId } = useUtils();
  const analyticsOptions = {
    apiUrl: `${getAnalyticsUrl() as string}`,
    appId: "ots",
    userId: getSessionId(),
    tenant: "ots",
    appName: appName() as string,
    useZenAnalytics: true,
    useGtag: useRuntimeConfig().public.analyticsEnabled,
    gtag: {
      enabled: useRuntimeConfig().public.nodeEnv === "production",
      config: {
        id: useRuntimeConfig().public.gaTagIds,
      },
    },
    appReleaseNumber: appVersion.version,
    useageType: "user",
    eventId: "",
    useZenClientHeader: false,
  };

  useAnalytics(analyticsOptions);

  isConsent.value = !!consent.value;

  appMounted.value = true;
});

function onCookieConsentAccepted(): void {
  isConsent.value = true;
  consent.value = "true";
  sendScreenView({ type: "click", target: "gdpr-accepted" });
}
</script>

<template>
  <TheHeader />
  <NuxtPage />
  <TheFooter />
  <LazyConsentFooter v-if="!isConsent" role="dialog" @accepted="onCookieConsentAccepted" />
</template>
