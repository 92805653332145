<script setup lang="ts">
const showStayInTheLoop = ref(false);
</script>

<template>
  <div class="feature times-travel-feature">
    <HomeFeatureContentContainer>
      <img
        :src="`${useCdnUrl()}/assets/times-travel/times-travel-logo.svg`"
        alt="Times Travel"
        class="section-logo"
      />
      <p>
        Experience time in unexpected ways and journey through decades of change. In some moments,
        <i>time flies</i> and in others it's <i>eternal midnight</i> - all leading to a spectacular
        360-degree viewing deck above Times Square, with an elevated perspective of the New Year's
        Eve Ball and the city from river to river.
      </p>

      <p class="coming-soon">Join the waitlist and be the first to know when ticket sales open</p>

      <TheButton
        tag="button"
        class="times-travel-callout"
        aria-label="Sign up for waitlist here"
        @click="showStayInTheLoop = true"
        >Join the waitlist</TheButton
      >
    </HomeFeatureContentContainer>

    <div class="feature-image-effect">
      <SplitImage
        :srcs="[`${useCdnUrl()}/assets/times-travel/times-travel-feature-placeholder.webp`]"
        :alt="['Times Travel feature image']"
      />
    </div>

    <div class="feature-image-mobile">
      <div
        class="feature-image-background"
        :style="{
          'background-image': `url(${useCdnUrl()}/assets/times-travel/times-travel-feature-placeholder.webp)`,
        }"
      />
      <img :src="`${useCdnUrl()}/assets/times-travel/times-travel-logo.svg`" alt="Times Travel" />
    </div>
  </div>

  <Teleport to="body">
    <TheModal v-if="showStayInTheLoop" @close-modal="showStayInTheLoop = false">
      <template #content>
        <FormsSignUp @close-modal="showStayInTheLoop = false" />
      </template>
    </TheModal>
  </Teleport>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.times-travel-feature {
  background-color: var(--times-travel-primary-color);
  .feature-content {
    background-color: var(--times-travel-primary-color);

    .times-travel-callout {
      background-color: var(--times-travel-complimentary-color);
      border-width: 0;

      &:hover {
        color: black;
      }
    }
  }

  .feature-image-effect {
    .split-image {
      .split-slider {
        background-color: var(--times-travel-primary-color) !important;
      }
    }
  }
}
</style>
